import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Toolbar from '../components/Toolbar';
import Footer from 'components/Footer';

export default function MainLayout() {

    return (
        <Fragment>
            <Header/>

            <main>
                <Outlet />
            </main>

            <Toolbar />

            <Footer/>
        </Fragment>
    )
}
