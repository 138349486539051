import BookingLayout from "layouts/BookingLayout";
import MainLayout from "../layouts/MainLayout";
import NoHeaderLayout from "../layouts/NoHeaderLayout";
import UserLayout from "../layouts/UserLayout";
import assistenza from "./assistenza";
import booking from "./booking";
import business from "./business";
import general from "./general";
import pagine from "./pagine";
import profilo from "./profilo";
import user from "./user";
import promo from "./promo";
import blog from "./blog";
import prenotazione from "./prenotazione";

const routes =  [{
    layout: MainLayout,
    routes: [
        ...general,
        ...blog,
        ...pagine,
        ...profilo,
        ...prenotazione,
        ...assistenza,
        ...promo
    ]
}, {
    layout: NoHeaderLayout,
    routes: [
        // ...home,
        ...business
    ]
}, {
    layout: BookingLayout,
    routes: [
        ...booking
    ]
}, {
    layout: UserLayout,
    routes: [
        ...user
    ]
}]

export default routes;