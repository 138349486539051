import { lazy } from 'react';

const ListaPromoPage = lazy(() =>  import('pages/Promo/Lista'));

export default [{
    path: '/promo',
    element: ListaPromoPage,
    exact: true,
    analytics: { name: ["Promo"] },
    private: true,
// },{
//     path: '/promo/:promoSlug',
//     element: DettaglioParcheggiPage,
//     exact: true,
//     analytics: { name: ["Promo", "Dettaglio"] },
//     private: true,
}]
