import { lazy } from 'react';

const NotFound = lazy(() =>  import('pages/General/NotFound'));
const HomePage = lazy(() =>  import('pages/General/Home'));
const SearchPage = lazy(() =>  import('pages/General/Search'));
const CercaPrenotazionePage = lazy(() =>  import('pages/General/CercaPrenotazione'));
const SiteMap = lazy(() =>  import('pages/General/SiteMap'));
const TermsPage = lazy(() =>  import('pages/General/Terms'));

export default [{
    path: "*",
    element: NotFound,
    analytics: { name: ["404"]},
}, {
    path: '/',
    element: HomePage,
    exact: true,
    private: true,
},{
    path: '/ricerca',
    element: SearchPage,
    exact: true,
    analytics: { name: ["Ricerca"] },
    private: true,
},{
    path: "/404",
    element: NotFound,
    analytics: { name: ["404"]},
},{
    path: "/mappa-del-sito",
    element: SiteMap,
    analytics: { name: ["Mappa del sito"]},
},{
    path: "/cerca-prenotazione",
    element: CercaPrenotazionePage,
    analytics: { name: ["Prenotazioni", "Cerca"]},
},{
    path: "/termini-e-condizioni",
    element: TermsPage,
    analytics: { name: ["Termini e condizioni"]},
}]
