import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

export default function UserLayout() {

    return (
        <Fragment>
            <main>
                <Outlet />
            </main>
        </Fragment>
    )
}
