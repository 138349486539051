import React from "react";
import { Helmet } from "react-helmet";

export default function ScriptsHead() {

    return (

        <Helmet>
			<meta name="facebook-domain-verification" content="abbndp6losop1unojc3x0oyot1y3zi" />

			<script>
				{`
					!function(f,b,e,v,n,t,s)
					{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
					n.callMethod.apply(n,arguments):n.queue.push(arguments)};
					if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
					n.queue=[];t=b.createElement(e);t.async=!0;
					t.src=v;s=b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t,s)}(window, document,'script',
					'https://connect.facebook.net/en_US/fbevents.js');
					fbq('init', '${process.env.REACT_APP_FB_ID}');
					fbq('track', 'PageView');
				`}
			</script>

			<script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_ID}`}></script>
			<script>
				{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());

					gtag('config', '${process.env.REACT_APP_GA_ID}');
					gtag('config', '${process.env.REACT_APP_AW_ID}');

					gtag('set', {
						'currency': 'EUR'
					});
				`}
			</script>
        </Helmet>
    )
}