import React from 'react'
import { Link } from 'react-router-dom'

import MainContainer from '@parkagent/ui/dist/components/MainContainer'
import Paragraph from '@parkagent/ui/dist/components/Paragraph'
import { Envelope, Phone } from '@phosphor-icons/react'
import { menu } from 'data/menu'

const social = [{
    label: 'Facebook',
    image: '/images/social/facebook-white.png',
    href: 'https://www.facebook.com/ParkAgent.it'
}, {
    label: 'Instagram',
    image: '/images/social/instagram-white.png',
    href: 'https://www.instagram.com/parkagent.it/'
}, {
    label: 'Linkedin',
    image: '/images/social/linkedin-white.png',
    href: 'https://www.linkedin.com/company/parkagent/'
},{
    label: 'Youtube',
    image: '/images/social/youtube-white.png',
    href: 'https://www.youtube.com/channel/UCwO8QsdECV90eLRAcJILN5Q'
}]

export default function Footer() {

    return (
        <footer className='w-full bg-black pt-10 pb-120 lg:pb-20 lg:pt-20 print:hidden'>
            <MainContainer>
                <div className='flex justify-between items-start'>
                    <div className=''>
                        <div className='md:flex gap-4 items-center pb-3'>
                            <Link className='flex items-center text-xl text-white hover:text-primary' to="tel:0494906510">
                                <Phone className='w-5 h-5 fill-primary mr-2'/>
                                049 4906510
                            </Link>

                            <Link className='flex items-center text-xl text-white hover:text-primary' to="mailto:info@parkagent.it" target='_blank' rel='noreferrer noopener'>
                                <Envelope className='w-5 h-5 fill-primary mr-2'/>
                                info@parkagent.it
                            </Link>
                        </div>

                        <Paragraph color="gray" light small>Servizio clienti dal Lunedì al Venerdì dalle 09:00 alle 20:00 | il Sabato dalle 09:00 alle 15:00</Paragraph>
                    </div>

                    <div className='flex justify-center items-center'>
                        {social?.map((item, index) => {
                            return (
                                <Link to={item.href} key={index} target="_blank" className='px-3 py-1 hover:opacity-60'>
                                    <img src={item.image} alt={item.label} className='w-5 h-auto'/>
                                </Link>
                            )
                        })}
                    </div>
                </div>

                <div className='pt-10'>
                    <div className='flex flex-wrap gap-1'>
                        <Link to={`/mappa-del-sito`} className='text-gray text-sm hover:text-white pr-2'>Mappa del sito</Link>
                        {menu?.map((page, index) => {
                            return (
                                <Link key={index} to={`${page?.href}`} className='text-gray text-sm hover:text-white pr-2'>
                                    {page?.label}
                                </Link>
                            )
                        })}
                    </div>
                </div>

                <div className='flex flex-col md:flex-row md:justify-between md:items-start pt-10'>
                    <div className='w-full md:w-auto order-2 md:order-1'>
                        <div className='flex flex-wrap gap-1'>

                            <Link to={'/termini-e-condizioni'} className='text-gray text-sm hover:text-white pr-2'>Termini e condizioni di utilizzo</Link>
                            <Link target='_blank' to={'https://www.iubenda.com/privacy-policy/7825811'} className='iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe text-gray text-sm hover:text-white pr-2'>Privacy Policy</Link>
                            <Link target='_blank' to={'https://www.iubenda.com/privacy-policy/7825811/cookie-policy'} className='iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe text-gray text-sm hover:text-white pr-2'>Cookies Policy</Link>

                        </div>
                    </div>
                    <div className='w-full md:w-auto flex justify-start pb-10 md:justify-center order-1 md:order-2 md:items-center md:pb-3'>
                        <div className='md:flex flex-wrap gap-4 items-center'>
                            <Paragraph color="gray" light small>Design by <Link to="https://www.opificiolamantinianonimi.com">Opificio Lamantini Anonimi</Link></Paragraph>
                        </div>
                    </div>
                </div>

            </MainContainer>
        </footer>
    )
}
