import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Route as DomRoute, Routes } from "react-router-dom";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ErrorBoundary } from "react-error-boundary";

import routes from './routes';

import { UserProvider } from 'contexts/user';
import { CatalogProvider } from 'contexts/catalog';
import { SearchProvider } from 'contexts/search';

import Route from 'components/Routes';

import NotFoundPage from 'pages/General/NotFoundPage';

import './index.css';
import './stylesheets/index.js';
import ScriptsFooter from 'components/ScriptsFooter';
import ScriptsHead from 'components/ScriptsHead';

const queryCache = new QueryClient({
    defaultConfig: {
        retry: 1
    },
    retry: 0
})

function fallbackRender({ error }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: "red" }}>{error.message}</pre>
        </div>
    );
}

function App() {

    return (
        <QueryClientProvider client={queryCache}>
            <Suspense>
                <ErrorBoundary fallbackRender={fallbackRender}>
                    <Router>
                        <UserProvider>
                            <CatalogProvider>
                                <SearchProvider>
                                    <ScriptsHead />
                                    <Routes>
                                    {routes?.map((layout, index) => {
                                        return (
                                            <DomRoute key={index} element={<layout.layout />} >
                                                {layout.routes.map((route, indexRoute) => {
                                                    return (
                                                        <DomRoute
                                                            key={`${index}-${indexRoute}`}
                                                            {...route}
                                                            element={
                                                                <Route route={route}>
                                                                    <route.element/>
                                                                </Route>
                                                            }
                                                        >
                                                            {route?.routes?.map((subroute, subIndex) =>
                                                                <DomRoute
                                                                    key={`${subIndex}-${index}-${indexRoute}`}
                                                                    {...subroute}
                                                                    element={
                                                                        <Route route={subroute}>
                                                                            <subroute.element/>
                                                                        </Route>
                                                                    }
                                                                />
                                                            )}
                                                        </DomRoute>
                                                    )
                                                })}
                                            </DomRoute>
                                        )
                                    })}

                                        <DomRoute
                                            path="*"
                                            render={() => <NotFoundPage />}
                                        />
                                    </Routes>
                                    <ScriptsFooter />
                                </SearchProvider>
                            </CatalogProvider>
                        </UserProvider>
                    </Router>
                </ErrorBoundary>
            </Suspense>

            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}

export default App;
