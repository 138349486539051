import { lazy } from 'react';

const LoginPage = lazy(() =>  import('pages/User/Login'));
const SignupPage = lazy(() =>  import('pages/User/Signup'));
const AskPassword = lazy(() =>  import('pages/User/AskPassword'));
const ResetPassword = lazy(() =>  import('pages/User/ResetPassword'));

export default [
    {
        path: "/user/login",
        element: LoginPage,
        analytics: { name: ["Login"] },
    },{
        path: "/user/signup",
        element: SignupPage,
        analytics: { name: ["Signup"] },
    },{
        path: "/user/password/richiedi",
        element: AskPassword,
        analytics: { name: ["Richiedi cambio password"] },
    },{
        path: "/user/password/reset",
        element: ResetPassword,
        analytics: { name: ["Reset password"] }
    }
]
