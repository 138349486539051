import React, { Fragment, useEffect } from 'react'
import Cookies from 'js-cookie';

import HeadSeo from 'components/HeadSeo';
import qs from 'qs'


export default function Route({route, children}) {

    const { analytics } = route;
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const utmQueries = Object.keys(query || {}).filter(key => key.startsWith('utm_'));
    useEffect(() => {
        if (utmQueries.length) {
            utmQueries.forEach(key => {
                Cookies.set(`pa_${key}`, query[key], { expires: 3 });
            });
        }
        return;
    }, [query, utmQueries]);

    return (
        <Fragment>
            <HeadSeo title={analytics?.name} />
            {children}
        </Fragment>
    )
}