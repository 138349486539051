import React, { Fragment } from "react";

export default function ScriptsFooter() {

    return (

        <Fragment>
			<script type="text/javascript">
				{`
					(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
				`}
			</script>
			<script type="text/javascript">
				{`
					var _iub = _iub || [];
					_iub.csConfiguration = {"perPurposeConsent":true,"siteId":526134,"whitelabel":false,"cookiePolicyId":7825811,"lang":"it", "banner":{ "acceptButtonDisplay":true,"closeButtonRejects":true,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"ownerName":"www.parkagent.it","position":"float-bottom-right","rejectButtonDisplay":true,"showPurposesToggles":true,"showTotalNumberOfProviders":true,"slideDown":false,"zIndex":2 }};
				`}
			</script>
			<script type="text/javascript" src="//cs.iubenda.com/sync/526134.js"></script>
			<script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" async></script>
        </Fragment>
    )
}