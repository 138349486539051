import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { menu } from 'data/menu'
import { useUser } from 'contexts/user'
import { UserCircle, ChatCircleDots, SignIn } from '@phosphor-icons/react'

export default function Header({
    transparentStart,
    noMenu
}) {

    const [isFixed, setIsFixed] = useState(false)
    const { isLoggedIn, hasAgencies, hasParkings } = useUser()
    const location = useLocation();
    const isHome = location.pathname === '/'

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setIsFixed(true)
            } else {
                setIsFixed(false)
            }
        })
    }, [])

    const isTransparent = (transparentStart || isHome) && !isFixed

    const HeaderClasses = classNames({
        'w-full fixed left-0 z-100 flex justify-between items-center z-10 transition-all top-0': true,
        'bg-transparent': isTransparent,
        'bg-white shadow-md': !isTransparent,
    })

    const MenuItemClasses = classNames({
        'block text-sm leading-none hover:text-primary': true,
        'text-white': isTransparent,
        'text-black': !isTransparent
    })

    let menuList = menu?.filter(({href}) => {
        if(hasAgencies) {
            return href !== '/parkagent-agenzia-viaggi'
        }
        return href;
    })

    return (
        <div className={HeaderClasses}>
            <a href="/" className='py-4 px-5 md:px-6'>
                {isTransparent ?
                    <img src="/images/logo-white.png" className="h-9 w-auto" title="Parkagent" alt="Parkagent"/>
                :
                    <img src="/images/logo.png" className="h-9 w-auto" title="Parkagent" alt="Parkagent"/>
                }
            </a>

            <div className='hidden md:flex flex-1 items-center justify-end'>
                {!noMenu && menuList?.map((item, index) => {
                    return (
                        <div key={index} className='px-2'>
                            <Link to={item.href} className={MenuItemClasses}>{item.label}</Link>
                        </div>
                    )
                })}
                {hasAgencies &&
                    <Link className='hidden md:flex' to={process.env.REACT_APP_DOMAIN_AGENZIE}>
                        <div className={`flex items-center rounded-lg py-1.5 pl-3 pr-4 border transition-all ${isTransparent ? 'bg-white border-transparent bg-opacity-10 text-white' : 'bg-transparent border-line text-dark hover:bg-extralightprimary'}`}>
                            <SignIn className={`w-5 h-5 mr-1 ${isTransparent ? 'fill-white' : 'fill-dark'}`}/>
                            Vai ad Agenzie
                        </div>
                    </Link>
                }
                {hasParkings &&
                    <Link className='hidden md:flex' to={process.env.REACT_APP_DOMAIN_AGENZIE}>
                        <div className={`flex items-center rounded-lg py-1.5 pl-3 pr-4 border transition-all ${isTransparent ? 'bg-white border-transparent bg-opacity-10 text-white' : 'bg-transparent border-line text-dark hover:bg-extralightprimary'}`}>
                            <SignIn className={`w-5 h-5 mr-1 ${isTransparent ? 'fill-white' : 'fill-dark'}`}/>
                            Vai a Parcheggi
                        </div>
                    </Link>
                }
            </div>

            <div className='flex pr-6'>
                <div className='hidden md:flex items-center h-24 pl-2'>
                    {isLoggedIn ?
                        <Link to='/profilo/prenotazioni'>
                            <div className={`p-1.5 rounded-lg transition-all ${isTransparent ? 'text-white hover:bg-white hover:bg-opacity-10' : 'bg-transparent border-transparent text-dark hover:bg-extralightprimary'}`}>
                                <UserCircle className={`w-7 h-7 ${isTransparent ? 'fill-white' : 'fill-dark'}`}/>
                            </div>
                        </Link>
                    :
                        <Link className='hidden md:flex' to="/user/login">
                            <div className={`flex items-center rounded-lg py-1.5 pl-3 pr-4 border transition-all ${isTransparent ? 'bg-white border-transparent bg-opacity-10 text-white' : 'bg-transparent border-line text-dark hover:bg-extralightprimary'}`}>
                                <SignIn className={`w-5 h-5 mr-1 ${isTransparent ? 'fill-white' : 'fill-dark'}`}/>
                                Accedi
                            </div>
                        </Link>
                    }
                </div>
                <div className='md:hidden py-4'>
                    <Link to='/assistenza'>
                        <ChatCircleDots className={`w-7 h-7 ${isTransparent ? 'fill-white' : 'fill-dark'}`}/>
                    </Link>
                </div>
            </div>
        </div>
    )
}
