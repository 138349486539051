import { lazy } from 'react';

const ProfiloPrenotazioniPage = lazy(() =>  import('pages/Profilo/Prenotazioni'));
const ProfiloMezziPage = lazy(() =>  import('pages/Profilo/Mezzi'));
const ProfiloCouponsPage = lazy(() =>  import('pages/Profilo/Coupons'));
const ProfiloModificaPage = lazy(() =>  import('pages/Profilo/Modifica'));
const Profilo = lazy(() =>  import('pages/Profilo/Profilo'));

export default [{
    path: "/profilo",
    element: Profilo,
    analytics: { name: ["Profilo"]},
    routes: [
        {
            path: '/profilo',
            element: ProfiloPrenotazioniPage,
            analytics: { name: ["Profilo"] },
            private: true,
        },{
            path: '/profilo/prenotazioni',
            element: ProfiloPrenotazioniPage,
            analytics: { name: ["Profilo", "Prenotazioni"] },
            private: true,
        },{
            path: '/profilo/coupon',
            element: ProfiloCouponsPage,
            analytics: { name: ["Profilo", "Coupon"] },
            private: true,
        },{
            path: '/profilo/mezzi',
            element: ProfiloMezziPage,
            analytics: { name: ["Profilo", "Mezzi"] },
            private: true,
        },{
            path: '/profilo/modifica',
            element: ProfiloModificaPage,
            analytics: { name: ["Profilo", "Modifica"] },
            private: true,
        }
    ]
}]
