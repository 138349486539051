import { lazy } from 'react';

const PrenotazionePage = lazy(() =>  import('pages/General/Prenotazione'));
const VoucherPage = lazy(() =>  import('pages/General/Voucher'));
const FatturaPage = lazy(() =>  import('pages/General/Fattura'));
const NuovaRecensione = lazy(() =>  import('pages/Recensioni/NuovaRecensione'));
const GrazieRecensione = lazy(() =>  import('pages/Recensioni/Grazie'));

export default [{
    path: "/prenotazione/:reservationId",
    element: PrenotazionePage,
    analytics: { name: ["Prenotazioni", "Dettaglio"]},
},{
    path: "/prenotazione/:reservationId/voucher",
    element: VoucherPage,
    analytics: { name: ["Prenotazioni", "Voucher"]},
},{
    path: "/prenotazione/:reservationId/recensione",
    element: NuovaRecensione,
    analytics: { name: ["Prenotazioni", "Recensione"]},
},{
    path: "/prenotazione/:reservationId/recensione/grazie",
    element: GrazieRecensione,
    analytics: { name: ["Prenotazioni", "Recensione","Grazie"]},
},{
    path: "/prenotazione/:reservationId/fattura/:invoiceId",
    element: FatturaPage,
    analytics: { name: ["Prenotazioni", "Fattura"]},
}]
