import { lazy } from 'react';

const DettaglioPaginaPage = lazy(() =>  import('pages/Pagine/Dettaglio'));
const RecensioniPagina = lazy(() =>  import('pages/Pagine/Recensioni'));

export default [{
    path: '/:pageSlug',
    element: DettaglioPaginaPage,
    exact: true,
    analytics: { name: ["Pagine", "Dettaglio"] }
},{
    path: '/:pageSlug/recensioni',
    element: RecensioniPagina,
    exact: true,
    analytics: { name: ["Pagine", "Recensioni"] }
}]
