import { lazy } from 'react';

const Quotation = lazy(() =>  import('pages/Booking/Quotation'));
const Booking = lazy(() =>  import('pages/Booking/Booking'));
const User = lazy(() =>  import('pages/Booking/Customer'));
const Vehicle = lazy(() =>  import('pages/Booking/Vehicle'));
const Services = lazy(() =>  import('pages/Booking/Services'));
const Questions = lazy(() =>  import('pages/Booking/Questions'));
const Insurance = lazy(() =>  import('pages/Booking/Insurance'));
const Payment = lazy(() =>  import('pages/Booking/Payment'));
const BillingProfile = lazy(() =>  import('pages/Booking/BillingProfile'));
const Completed = lazy(() =>  import('pages/Booking/Completed'));
const Reservation = lazy(() =>  import('pages/General/Prenotazione'));

export default [{
    path: '/quotation',
    element: Quotation,
    analytics: { name: ["Quotation"] }
},{
    path: '/booking/completed',
    element: Completed,
    analytics: { name: ["Booking", "Completed"] }
},{
    path: '/booking/billingprofile',
    element: BillingProfile,
    analytics: { name: ["Booking", "Profilo di fatturazione"] }
},{
    path: '/booking',
    element: Booking,
    analytics: { name: ["Booking"] },
    routes: [
        {
            path: '/booking/customer',
            element: User,
            analytics: { name: ["Booking", "Customer"] }
        },{
            path: '/booking/vehicle',
            element: Vehicle,
            analytics: { name: ["Booking", "Vehicle"] }
        },{
            path: '/booking/services',
            element: Services,
            analytics: { name: ["Booking", "Services"] }
        },{
            path: '/booking/questions',
            element: Questions,
            analytics: { name: ["Booking", "Questions"] }
        },{
            path: '/booking/insurance',
            element: Insurance,
            analytics: { name: ["Booking", "Insurance"] }
        },{
            path: '/booking/payment',
            element: Payment,
            analytics: { name: ["Booking", "Payment"] }
        // },{
        //     path: '/booking/completed',
        //     element: Completed,
        //     analytics: { name: ["Booking", "Completed"] }
        }
    ]
},{
    path: '/reservations/:reservationId',
    element: Reservation
}]
