
import { lazy } from 'react';

const Blog = lazy(() =>  import('pages/Blog/Blog'));
const BlogArticle = lazy(() =>  import('pages/Blog/BlogArticle'));

export default [{
    path: '/blog-e-news',
    element: Blog,
    exact: true
}, {
    path: '/blog-e-news/:categorySlug',
    element: Blog,
    exact: true
}, {
    path: '/blog-e-news/:categorySlug/:articleSlug',
    element: BlogArticle,
    exact: true
}]
