import { lazy } from 'react';

const CarMoving = lazy(() =>  import('pages/Servizi/CarMoving'));
const CarValet = lazy(() =>  import('pages/Servizi/CarValet'));
const Agency = lazy(() =>  import('pages/Servizi/Agency'));
const Business = lazy(() =>  import('pages/Servizi/Business'));


export default [{
    path: '/car-moving-by-parkagent',
    element: CarMoving,
    exact: true,
    analytics: { name: ["Car Moving"] },
    private: true,
},{
    path: '/car-valet-by-parkagent',
    element: CarValet,
    exact: true,
    analytics: { name: ["Car Valet"] },
    private: true,
},{
    path: '/parkagent-agenzia-viaggi',
    element: Agency,
    exact: true,
    analytics: { name: ["Parkagent Agency"] },
    private: true,
},{
    path: '/parkagent-business',
    element: Business,
    exact: true,
    analytics: { name: ["Parkagent Business"] },
    private: true,
}]
