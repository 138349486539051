import { lazy } from 'react';

const Faqs = lazy(() =>  import('pages/Assistenza/Faqs'));
const ComeFunziona = lazy(() => import('pages/Servizi/ComeFunziona'));
const FaqDetail = lazy(() => import('pages/Assistenza/FaqDetail'));

export default [{
    path: '/assistenza',
    element: Faqs,
    exact: true,
    analytics: { name: ["Assistenza"] },
},{
    path: '/assistenza/:faqSlug',
    element: FaqDetail,
    analytics: { name: ["Assistenza", "Faq"] },
},{
    path: '/come-funziona',
    element: ComeFunziona,
    exact: true,
    analytics: { name: ["Come funziona"] },
}]
